import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'

export const EventualityBanner = ({ bannerData }) => {
  return (
    <div className="mt-5 mb-5 w-100 h-100 d-flex flex-wrap flex-sm-wrap  flex-md-nowrap font-secondary">
      {bannerData.image?.url ? (
        <div
          role="img"
          alt=""
          className="w-100 min-height-100 eventuality-banner-image"
          style={{
            backgroundImage: `url(${bannerData.image.url})`
          }}
        ></div>
      ) : null}
      <div
        role="img"
        alt={bannerData.imageDescription || ''}
        className={`min-height-100 w-100 d-flex flex-column ${
          bannerData?.image?.url
            ? 'align-items-start banner-content'
            : 'align-items-center py-5'
        }  justify-content-center bg-eventuality-banner-content font-primary `}
        style={{
          backgroundImage: bannerData?.image?.url
            ? `url(${bannerData.image.url})`
            : ''
        }}
      >
        <div>
          <div className={`${bannerData?.image?.url ? 'pl-3' : ''}`}>
            <h2 className="font-weight-bold text-uppercase">
              {bannerData.title}
            </h2>
            <ReactMarkdown
              className="font-weight-bolder"
              source={bannerData.content}
            />
            {bannerData.url ? (
              <a
                href={bannerData.url}
                target="_blank"
                className="btn rounded-pill font-secondary font-weight-medium bg-eventuality-button border-ucc-boton text-white px-4"
                rel="noreferrer"
              >
                VER MÁS
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

EventualityBanner.propTypes = {
  bannerData: PropTypes.object.isRequired
}
