import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../font-awesome'
import { Link } from 'gatsby'

export const Location = ({ data }) => {
  return (
    <div className="w-100 mt-5 pb-3">
      <div className="container">
          <h2 className="text-uppercase primary-color font-weight-extra-bold mb-0 text-center text-md-left ml-0 ml-md-5">
          {data.title}
        </h2>
        <div className="h-100 row justify-content-around">
          {data?.buildingsLocation.map((location, i) => (
            <div
              className="h-100 format-location-cards primary-color location-card-item col-12 col-md-4"
              key={`location-id${i}`}
            >
              <div className="d-flex flex-column align-items-center justify-content-center">
                <FontAwesomeIcon
                  icon={['fas', 'map-marker-alt']}
                  size="2x"
                  className="mb-1"
                />
                <div className="text-center">
                  <h5 className="location-card-title font-weight-bolder">
                    {location.buildingName}
                  </h5>
                </div>

                <p>{location.information}</p>

                <Link 
                  to={location.url}
                  target="_blank">
                    <button className="btn btn-outline-dark px-3 fit-content mt-auto rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary location-button ">
                      VER MAPA
                    </button>
                </Link>
              </div>  
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Location.propTypes = {
  data: PropTypes.object.isRequired
}
