import React from 'react'
import NoticesAndNovelties from '../../components/noticesAndNovelties'
import InternalLink from '../internalLink'
import PropTypes from 'prop-types'

const ResearchSection = ({ section, pages }) => {
  const numbers = section?.numbers.map((number) => {
    return (
      <div
        key={number.id}
        className="text-white col-12 col-md-6 col-lg-3 text-center py-3 research-col mb-3"
      >
        <p className="font-weight-extra-bold research-numbers mb-0 display-2">
          {number.quantity}
        </p>
        <p className="research-text">{number.description}</p>
      </div>
    )
  })
  const images = section?.imageLink.map((imageItem) => {
    return imageItem.url !== null && imageItem.url !== '' ? (
      <div
        key={imageItem.id}
        className="text-white col-12 col-md-6 col-lg-3 text-center py-3 research-col mb-3"
      >
        <a href={imageItem.url} className="research-col-img">
          <img
            src={imageItem.image?.url}
            className=""
            alt={imageItem.image?.alt}
          />
        </a>
      </div>
    ) : (
      <div
        key={imageItem.id}
        className="text-white col-12 col-md-6 col-lg-3 text-center py-3 research-col mb-3"
      >
        <div className="research-col-img">
          <InternalLink link={imageItem} pages={pages} />
        </div>
      </div>
    )
  })
  return (
    <section className="research">
      <div className="container mx-auto w-100 py-4">
        <h2 className="text-uppercase text-white font-weight-extra-bold mb-0 text-center text-md-left">
          {section.title}
        </h2>
        <div className="d-flex align-items-stretch justify-content-between py-5 flex-wrap flex-lg-nowrap">
          {numbers}
          {images}
        </div>
        {section?.noveltiesList && section?.noveltiesList.visible && (
          <div className="container research-novelties">
            <NoticesAndNovelties
              novelties={section.noveltiesList.novelties}
              isHomeUcc={true}
              showView={section.noveltiesList.showView}
            />
          </div>
        )}
      </div>
    </section>
  )
}

ResearchSection.propTypes = {
  section: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired
}

export default ResearchSection
