import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Social = ({ socialMedias, isHomeUcc = false }) => {
  const [visibleLength, setVisibleLength] = useState(0)
  const [codeFacebook, setCodeFacebook] = useState('')
  useEffect(() => {
    const array = Object.entries(socialMedias)
    setVisibleLength(array.filter((red) => red[1]?.visible).length)
    setCodeFacebook(
      socialMedias.facebook?.code
        ? socialMedias.facebook.code.replace('width="370"', 'width="100%"')
        : null
    )
  }, [socialMedias])

  useEffect(() => {
    if (window.instgrm) window.instgrm.Embeds.process()
  }, [])

  return (
    <div className="mb-5 container">
      {isHomeUcc ? (
        <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
          {socialMedias?.title}
        </h2>
      ) : (
        <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
          {socialMedias?.title}
        </h2>
      )}
      <div className="row">
        {socialMedias?.twitter?.visible && (
          <div
            className={`col-12 ${visibleLength === 1
              ? 'col-md-12'
              : visibleLength === 2
                ? 'col-md-6'
                : visibleLength === 3
                  ? 'col-md-4'
                  : ''
              } py-2`}
          >
            <div
              className="shadow-sm bg-white rounded twitter position-relative overflow-auto text-center"
              style={{ height: 500 + 'px' }}
              dangerouslySetInnerHTML={{
                __html: socialMedias.twitter.code
              }}
            ></div>
          </div>
        )}
        {socialMedias?.facebook?.visible && (
          <div
            className={`col-12 ${visibleLength === 1
              ? 'col-md-12'
              : visibleLength === 2
                ? 'col-md-6'
                : visibleLength === 3
                  ? 'col-md-4'
                  : ''
              } py-2`}
          >
            <div className="shadow-sm bg-transparent rounded facebook position-relative overflow-hidden text-center mx-auto d-flex justify-content-center align-content-center">
              <div
                className="facebook-cont m-n1"
                dangerouslySetInnerHTML={{
                  __html: codeFacebook
                }}
              ></div>
            </div>
          </div>
        )}
        {socialMedias?.instagram?.visible && (
          <div
            className={`col-12 ${visibleLength === 1
              ? 'col-md-12'
              : visibleLength === 2
                ? 'col-md-6'
                : visibleLength === 3
                  ? 'col-md-4'
                  : ''
              } py-2`}
          >
            <div className="insta bg-white shadow-sm rounded position-relative overflow-auto text-center">
              <div
                className="insta-container"
                dangerouslySetInnerHTML={{
                  __html: socialMedias.instagram.code
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Social

Social.propTypes = {
  socialMedias: PropTypes.object.isRequired,
  isHomeUcc: PropTypes.bool
}
