import PropTypes from 'prop-types'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPlayer from 'react-player'

const BannerVideo = ({ bannerVideo }) => {
  return (
    <div className="bannerVideo--padding-responsive py-4">
      <div className="">
        <div className="mx-auto row">
          <div className="col-12 col-md-6">
            <div className="embed-responsive embed-responsive-16by9 responsive-banner-video">
              <ReactPlayer
                className="embed-responsive-item"
                url={`https://www.youtube.com/embed/${bannerVideo.videoId}?rel=0`}
                controls={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex flex-column align-items-center ">
            <div className="w-100  h-100 d-flex flex-column  responsive-banner-video-content">
              <ReactMarkdown
                source={bannerVideo.summary}
                className="format-markdown-container-banner markdown-format secondary-color"
              />
              <div>
                {bannerVideo?.url ? (
                  <a
                    href={bannerVideo.url}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-sm btn-outline-dark ml-0 rounded-pill px-3 border-dark primary-color"
                  >
                    VER MÁS
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BannerVideo

BannerVideo.propTypes = {
  bannerVideo: PropTypes.object.isRequired
}
