import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import { createNavigationUrl } from '../../helpers/createNavigationUrl'

export const CardList = ({
  isLivingVocationData = false,
  isCountinousTraining = false,
  data
}) => {
  const paths = useStaticQuery(graphql`
    query {
      allSitePage(
        filter: {
          componentChunkName: {
            in: [
              "component---src-templates-main-render-ou-js"
              "component---src-templates-internal-page-js"
            ]
          }
        }
      ) {
        nodes {
          path
        }
      }
    }
  `)

  const { allSitePage: matchPages } = paths
  const [dataCards, setDataCards] = useState([])
  useEffect(() => {
    setDataCards(data.cards.filter((card) => card.visible === true))
  }, [])
  return (
    <div className="my-5">
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2 className="title-secondary text-uppercase font-weight-extra-bold ucc-text-blue">
            {data.title}
          </h2>
        </div>
      </div>
      <div className="row">
        {dataCards.map((newItem, i) => {
          const texto = newItem.content.split('#')
          let filtro = texto.filter(Boolean)
          return newItem.visible ? (
            <div
              className={`pb-4 col-12 ${
                newItem.destacado &&
                (dataCards.length === 1 || dataCards.length === 3)
                  ? 'col-md-12'
                  : 'col-md-6'
              }`}
              key={`${
                isLivingVocationData !== false
                  ? 'livingVocationData-' + i
                  : null
              }`}
            >
              <div
                className={`novelty-feed-img-index h-100 bg-terceary`}
                style={{
                  backgroundImage: `url(${
                    newItem.backgroundImage?.url
                      ? newItem.backgroundImage?.url
                      : 'none'
                  }`
                }}
              >
                <div className="text-white d-flex flex-column background h-100 justify-content-between">
                  {/* Etieta de las CARDs */}
                  <div className="h-100 d-flex justify-content-between flex-column position-relative">
                    {newItem.type ? (
                      <div
                        className={'m-3 bg-sec px-2 py-1 mb-2 fit-content '}
                        style={{
                          backgroundColor: newItem.color,
                          fontWeight: 'bold'
                        }}
                      >
                        {newItem.labelBlack === true ? (
                          <div className="text-black">{newItem.type}</div>
                        ) : (
                          <div>{newItem.type}</div>
                        )}
                      </div>
                    ) : (
                      <br></br>
                    )}
                    <div
                      className={`p-3 d-flex flex-column justify-content-between ${
                        !newItem.destacado
                          ? 'ucc-background-gradient '
                          : 'ucc-background-gradient-horizontal '
                      } `}
                    >
                      <ReactMarkdown
                        className={`markdown-format markdown-format-white ${
                          filtro[0].length > 18 ? 'text-content-car-title' : ''
                        }`}
                        source={newItem.content}
                      />

                      {newItem.url ? (
                        <a
                          href={newItem.url}
                          className="btn btn-outline-light px-4 fit-content rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          VER MÁS
                        </a>
                      ) : createNavigationUrl(matchPages?.nodes, newItem) !==
                        './' ? (
                        <Link
                          to={createNavigationUrl(matchPages?.nodes, newItem)}
                          className="btn btn-outline-light px-4 fit-content rounded-pill text-uppercase eventuality-button-font-size font-weight-medium font-secondary"
                        >
                          VER MÁS
                        </Link>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        })}
      </div>
    </div>
  )
}

CardList.propTypes = {
  data: PropTypes.object.isRequired,
  isLivingVocationData: PropTypes.bool,
  isCountinousTraining: PropTypes.bool
}
