import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import BannerVideo from '../components/home/banner-video'
import { Button } from '../components/button'
import { FloatButton } from '../components/home/floatButton'
import { CardList } from '../components/home/cardList'
import { EventualityBanner } from '../components/home/eventualityBanner'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { Location } from '../components/home/location'
import Schedule from '../components/schedule'
import NoticesAndNovelties from '../components/noticesAndNovelties'
import Social from '../components/social'
import ResearchSection from '../components/home/researchSection'
import { Formulario } from '../components/form'
import LogosSection from '../components/logosSection'
import NotesPreview from '../components/notesPreview'

const IndexPage = (props) => {
  const { data } = props
  const { strapiInstitution: institution, allStrapiDiary: diaries } = data

  return (
    <Layout page={institution} indexHeader={true} header={institution.header}>
      <Seo />
      <section>
        {institution?.bannerAlert && institution?.bannerAlert.visible && (
          <EventualityBanner bannerData={institution.bannerAlert} />
        )}{' '}
        <div className="">
          {institution?.dynamicButton && institution?.dynamicButton.button && (
            <div className="container my-5">
              <Button
                icon={institution.dynamicButton?.icon}
                button={institution.dynamicButton.button}
                customButton={institution.dynamicButton.customButton}
                key={`buttonId-${institution?.dynamicButton.id}`}
              />
            </div>
          )}
          {institution?.startVocation && institution?.startVocation.visible && (
            <div className="container my-5">
              <CardList
                data={institution.startVocation}
                isLivingVocationData={true}
                pages={data.allSitePage.nodes}
              />
            </div>
          )}
          {institution?.keepTraining && institution?.keepTraining.visible && (
            <div className="container my-5">
              <CardList
                data={institution.keepTraining}
                pages={data.allSitePage.nodes}
                isCountinousTraining={true}
              />
            </div>
          )}
          {institution?.bannerAlertTwo && institution?.bannerAlertTwo.visible && (
            <EventualityBanner bannerData={institution.bannerAlertTwo} />
          )}{' '}
          {institution.form && institution.form.form.id !== null && (
            <Formulario
              idForm={institution.form.form.id}
              title={institution.form?.title}
              key={`formId-${institution.form.form.id}`}
              receiverGeneral={institution.form.interest_receiver?.receiver}
              typeGeneral={institution.form.interest_type}
            />
          )}
          {institution?.noveltiesList && institution?.noveltiesList.visible && (
            <div className="my-5">
              <NoticesAndNovelties
                novelties={institution.noveltiesList.novelties}
                isHomeUcc={true}
                showView={institution.noveltiesList.showView}
              />
            </div>
          )}
          {diaries?.nodes.length > 0 && (
            <Schedule isHomeUcc={true} diaries={diaries.nodes} />
          )}
          {institution?.socialMedias && institution?.socialMedias.visible && (
            <div className="container my-5">
              <Social
                socialMedias={institution.socialMedias}
                isHomeUcc={true}
              />
            </div>
          )}
          {institution?.logosSection && (
            <div className="container my-5">
              <LogosSection
                data={institution.logosSection}
              />
            </div>
          )}
          {institution?.researchSection &&
            institution?.researchSection !== null && (
              <div className="mt-5">
                <ResearchSection
                  section={institution.researchSection}
                  pages={data.allSitePage.nodes}
                />
              </div>
            )}
          {institution?.bannerVideo && institution?.bannerVideo.visible && (
            <div className="">
              <BannerVideo bannerVideo={institution.bannerVideo} />
            </div>
          )}
          {institution?.notesList && institution.notesList.note !== null && (
            <div className="mt-5">
              <NotesPreview 
                notes={institution.notesList.note} 
                isHomeUcc={true}
              />
            </div>
          )}
          {institution?.bannerAlertThree && institution?.bannerAlertThree.visible && (
            <EventualityBanner bannerData={institution.bannerAlertThree} />
          )}{' '}
          {institution?.buildingLocation &&
            institution?.buildingLocation.visible && (
              <>
                <Location data={institution?.buildingLocation} />
              </>
            )}
          <FloatButton />
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

export const indexPageQuery = graphql`
  query {
    strapiInstitution {
      name
      id
      header {
        visible
        slide {
          name
          visible
          imageDescription
          textButton
          content
          url
          backgroundImage {
            url
            name
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
      primaryLogo {
        image {
          url
        }
        url
      }
      secondaryLogo {
        image {
          url
        }
      }
      secondaryLogoDark {
        image {
          url
        }
      }
      navbar {
        url
        name
        icon {
          code
          type
        }
        ExternalLink {
          name
          url
          id
          icon {
            code
            type
          }
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            id
            name
            slug
          }
          id
        }
        pages {
          name
          icon {
            code
            type
          }
          page {
            id
            name
            slug
          }
          id
        }
        page {
          slug
          name
          id
        }
        organizational_unit {
          id
          name
          slug
        }
        multipleSite {
          name
          url
          icon {
            code
            type
          }
          page {
            id
            name
            slug
          }
          organizational_unit {
            id
            name
            slug
          }
          novelty {
            id
            title
            slug
          }
          note {
            id
            title
            slug
          }
          ucc_career {
            id
            name
            slug
          }
        }
      }
      dynamicButton {
        icon {
          code
          type
        }
        customButton {
          shape
          size
          variant
        }
        button {
          url
          label
          career_level {
            name
          }
          diary {
            slug
          }
          label
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
      }
      startVocation {
        title
        visible
        cards {
          cardName
          type
          content
          url
          visible
          destacado
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
          }
          organizational_unit {
            slug
          }
          page {
            name
            id
            slug
          }
          career_level {
            name
          }
        }
      }
      bannerAlert {
        content
        imageDescription
        title
        visible
        url
        image {
          name
          url
        }
      }
      bannerAlertTwo {
        content
        imageDescription
        title
        visible
        url
        image {
          name
          url
        }
      }
      bannerAlertThree {
        content
        imageDescription
        title
        visible
        url
        image {
          name
          url
        }
      }
      keepTraining {
        title
        visible
        cards {
          cardName
          content
          url
          visible
          destacado
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
          }
          page {
            id
            name
            slug
          }
          career_level {
            name
          }
          type
        }
      }
      buildingLocation {
        title
        visible
        buildingsLocation {
          buildingName
          url
          information
        }
      }
      bannerVideo {
        id
        summary
        videoId
        url
        visible
      }
      noveltiesList {
        title
        visible
        showView
        novelties {
          destacado
          noveltyName
          novelty {
            id
            summary
            title
            url
            slug
            thumbnailName
            thumbnail {
              url
            }
            published_at(formatString: "DD MMMM, YYYY", locale: "es")
            dateTo(formatString: "YYYY/MM/DD", locale: "es")
            date(formatString: "YYYY/MM/DD", locale: "es")
          }
        }
      }
      notesList {
        id
        title
        visible
        showView
        latest
        note {
          noteName
          note {
            id
            title
            slug
            date
            summary
            thumbnail {
              url
            }
          }
        }
      }
      researchSection {
        id
        title
        numbers {
          id
          quantity
          description
        }
        imageLink {
          id
          alt
          url
          organizational_unit {
            id
            name
            slug
          }
          page {
            id
            title
            name
            slug
            url
          }
          image {
            url
          }
        }
        noveltiesList {
          title
          visible
          showView
          novelties {
            destacado
            noveltyName
            novelty {
              id
              summary
              title
              url
              slug
              thumbnailName
              thumbnail {
                url
              }
              published_at(formatString: "DD MMMM, YYYY", locale: "es")
              dateTo(formatString: "YYYY/MM/DD", locale: "es")
              date(formatString: "YYYY/MM/DD", locale: "es")
            }
          }
        }
      }
      socialMedias {
        title
        visible
        facebook {
          code
          visible
        }
        instagram {
          code
          visible
        }
        twitter {
          code
          visible
        }
      }
      form {
        id
        title
        form {
          id
        }
      }
      logosSection {
        title
        summary
        media {
          name
          img {
            url
          }
          imageDark {
            url
          }
        }
      }
    }
    allStrapiDiary(
      filter: { academic_unit: { name: {ne: "ICDA"} } }
      limit: 20
      sort: { fields: date, order: DESC } 
    ) {
      nodes {
        title
        slug
        date(formatString: "YYYY/MM/DD", locale: "es")
        time
        place
        diary_category {
          name
        }
      }
    }
    allSitePage(
      filter: {
        componentChunkName: {
          in: [
            "component---src-templates-main-render-ou-js"
            "component---src-templates-internal-page-js"
          ]
        }
      }
    ) {
      nodes {
        path
      }
    }
  }
`
