import React, { useLayoutEffect, useState } from 'react'

export const FloatButton = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [showFloat, setShowFloat] = useState(false)
  const handleScrollFloatBtn = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  const scrollToUp = () => {
    window.scrollTo(0, 0)
  }
  useLayoutEffect(() => {

    window.addEventListener('scroll', handleScrollFloatBtn, { passive: true })

    setShowFloat(scrollPosition > window.innerHeight * 2)
    return () => {
      window.removeEventListener('scroll', handleScrollFloatBtn)
    }
  }, [scrollPosition])
  return (
    <>
      {showFloat && <div className="fixed-bottom">
        <button
          onClick={scrollToUp}
          className="w-100 btn rounded-0  ucc-background-color text-white"
        >
          Ir Arriba
        </button>
      </div>}
    </>
  )
}
