import React from 'react'
import { Link } from 'gatsby'
import { findUrl } from '../helpers/findUrl'


const InternalLink = ({ link, pages }) => {
  const internalLink =
    link.page !== null && link.page !== undefined ? (
      <Link to={findUrl(link.page?.slug, pages)}>
        <img src={link.image?.url} className="" alt={link.image?.alt} />
      </Link>
    ) : (
      <Link to={link.organizational_unit.slug}>
        <img src={link.image?.url} className="" alt={link.image?.alt} />
      </Link>
    )
  return <div>{internalLink}</div>
}

export default InternalLink
